import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { QuantityInput } from "../fields/QuantityInput";

const ResponsiveProductCard = ({ data }) => {
  // console.log(data, "____data___");
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(data.price);
  const handleQuantityChange = (event, newValue, index) => {
    setQuantity(newValue);
    setTotal(newValue * data.price);
  };
  return (
    <>
      <Stack
        sx={{
          marginBottom: 5,
          paddingHorizontal: 1,
          paddingTop: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          //   alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "120px",
            width: "150px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={data.image}
            style={{
              maxHeight: "120px",
              maxWidth: "120px",
            }}
          />
        </Box>
        <Box sx={{ padding: 1, width: "50%" }}>
          <Typography variant="h3" sx={{ fontSize: 22 }}>
            {data.name}
          </Typography>
          <Typography variant="h3" sx={{ fontSize: 18, marginTop: 1 }}>
            brand: {data.brand}
          </Typography>
          <Typography variant="h3" sx={{ fontSize: 18, marginTop: 1 }}>
            price: ${data.price}
          </Typography>
          <Typography variant="h3" sx={{ fontSize: 18, marginTop: 1 }}>
            Total: ${total}
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <QuantityInput
              // index={index}
              value={quantity}
              onChange={handleQuantityChange}
            />
          </Box>
        </Box>
      </Stack>
      <Divider />
    </>
  );
};

export default ResponsiveProductCard;
