import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Stack, Button } from "@mui/material";

const NotFoundPage = (props) => {
  const navigate = useNavigate();
  return (
    <Stack
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"90vh"}
      spacing={2}
    >
      <Typography variant="h1">404 Page not found</Typography>
    </Stack>
  );
};

export default NotFoundPage;
