import axios from "axios";
import urls from "./urls";
import { useLocalStorage } from "../hooks";

const ignore = [urls.login, urls.forgotPassword, urls.resetPassword];

// Create an Axios instance
const api = axios.create({
  baseURL: urls.baseURL.ekyc, // Your API base URL
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // You can modify the request config here (e.g., add headers)
    // For example, you might add an authorization header:
    // config.headers.Authorization = `Bearer ${yourAuthToken}`;

    if (!ignore.includes(config.url)) {
      // Check if there's a token in local storage and if it's expired
      const { getItem } = useLocalStorage();
      const accessToken = getItem("access_token");

      // if (accessToken) {
      //   // Add the access token to the headers
      //   config.headers.Authorization = `Bearer ${accessToken}`;
      // }
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // You can modify the response data here

    if (response.status === 200) {
      const data = response.data.data;
      const { setItem } = useLocalStorage();
      if (response.config.url === urls.login) {
        setItem("access_token", data.access_token);
        setItem("refresh_token", data.refresh_token);
      }
    }

    return response;
  },
  async (error) => {
    // Handle response errors
    // const originalRequest = error.config;
    // if (!ignore.includes(originalRequest.url)) {
    //   const { getItem, setItem, removeItem } = useLocalStorage();
    //   const accessToken = getItem("access_token");
    //   const refreshToken = getItem("refresh_token");

    //   // Check if the error is due to an expired token
    //   if (error.response.status === 401 && !originalRequest._retry) {
    //     originalRequest._retry = true;

    //     // Use the refresh token to get a new access token
    //     try {
    //       await api
    //         .post(urls.refreshToken, {
    //           access_token: accessToken,
    //           refresh_token: refreshToken,
    //         })
    //         .then((response) => {
    //           const data = response.data.data;
    //           setItem("access_token", data.access_token);

    //           // Update the original request with the new access token
    //           originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
    //         })
    //         .catch((error) => {
    //           removeItem("access_token");
    //           removeItem("refresh_token");
    //           removeItem("user_data");
    //         });
    //       // Retry the original request
    //       return api(originalRequest);
    //     } catch (refError) {
    //       // Handle refresh token error (e.g., redirect to login)
    //       console.error("Error refreshing token:", refError);
    //       return Promise.reject(refError);
    //     }
    //   }

    //   // Return any other errors
    //   return Promise.reject(error);
    // }

    // Return any other errors
    return Promise.reject(error);
  }
);

export default api;
