import { createTheme, alpha } from "@mui/material/styles";
import themeTypography from "./typography";

const generateTheme = (mode) => {
  const isDarkMode = mode === "dark";

  const palette = {
    mode,
    transparent: {
      main: isDarkMode ? "#00000000" : "#00000000",
    },
    common: {
      black: "#000",
      white: "#FFFFFF",
      lightGreyBg: "#F7F7F7",
      greyBg: "#E8E8E8",
      blueTxt: "#2C74FF",
      redBtn: "#FF1818",
      greyTxt: "#CECECE",
      bgclr: "#EDFAFF",
      greenBg: "#1ACA2C",
    },
    primary: {
      main: isDarkMode ? "#000000" : "#000000",
    },
    secondary: {
      main: isDarkMode ? "#969696" : "#969696",
    },
    tertiary: {
      main: isDarkMode ? "#5D5D5D" : "#5D5D5D",
    },
    background: {
      main: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    backgroundGray: {
      main: isDarkMode ? "#F6F6F6" : "#F6F6F6",
    },
    white: {
      main: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    black: {
      main: isDarkMode ? "#000000" : "#000000",
    },
    line: {
      main: isDarkMode ? "#E0E0E0" : "#E0E0E0",
    },
    red: {
      main: isDarkMode ? "#FA7474" : "#FA7474",
    },
    green: {
      main: isDarkMode ? "#19A089" : "#19A089",
      contrastText: isDarkMode ? "#FFFFFF" : "#FFFFFF",
    },
    lightGreen: {
      main: isDarkMode ? alpha("#19A089", 0.1) : alpha("#19A089", 0.1),
      contrastText: isDarkMode ? "#000000" : "#000000",
    },
  };

  return createTheme({
    palette: palette,
    typography: themeTypography(),
  });
};

export { generateTheme };
