import { useState } from "react";
import { api, urls } from "../services";

export const useAPI = (initial = false) => {
  const [isLoading, setIsLoading] = useState(initial);

  // Add a request interceptor
  api.interceptors.request.use(
    (config) => {
      // Show loader before making the API call
      setIsLoading(true);
      return config;
    },
    (error) => {
      // Handle request error
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  api.interceptors.response.use(
    (response) => {
      // Hide loader after the API call is complete
      setIsLoading(false);
      return response;
    },
    (error) => {
      // Handle response error
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  return { api, urls, isLoading };
};
