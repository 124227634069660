import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import { macbook } from "../../assets";
import { iPad } from "../../assets";

export function ProductCard(props) {
  const { image, productName, brandName } = props;
  return (
    <Stack direction={"row"} justifyContent={{ xs: "space-between", md: "left" }} gap={6} sx={{ width: { xs: "100%", md: "auto" } }}>
      <Box
        sx={{
          height: "120px",
          width: "150px",
          display: "flex",
          justifyContent: "center",
            
        }}
      >
        <img
          src={image}
          style={{
            maxHeight: "120px",
            maxWidth: "120px",
          }}
        />
      </Box>
      <Stack justifyContent={"center"} spacing={1}>
        <Typography variant="h2" sx={{ fontSize: 20 }}>
          {productName}
        </Typography>
        <Typography variant="h3" sx={{ fontSize: 18, color: "secondary.main" }}>
          {brandName}
        </Typography>
        {/* <Typography
          variant="p1"
          color={"red.main"}
          sx={{ fontWeight: 700, cursor: "pointer" }}
        >
          Remove
        </Typography> */}
      </Stack>
    </Stack>
  );
}
