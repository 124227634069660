// API URLs

export const baseURL = "https://devtoppanapi.hashinclude.io/";
// export const baseURL = "https://ekyc-api.demo.toppangravity.com/";
// export const baseURL = "https://toppanapi.hashinclude.io/";

const urls = {
  // base url
  baseURL: {
    dev: "https://devtoppanapi.hashinclude.io/api/v1/",
    prod: "https://toppanapi.hashinclude.io/api/v1/",
    ekyc: "https://ekyc-api.demo.toppangravity.com/api/v1/",
    // organisation 1 (zynk)
    // dev: "https://devtoppanapi.hashinclude.io/zynk/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/zynk/api/v1/",
    // // organisation 2 (quixo)
    // dev: "https://devtoppanapi.hashinclude.io/quixo/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/quixo/api/v1/",
    // // organisation 3 (pylo)
    // dev: "https://devtoppanapi.hashinclude.io/pylo/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/pylo/api/v1/",
    // // organisation 4 (vynx)
    // dev: "https://devtoppanapi.hashinclude.io/vynx/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/vynx/api/v1/",
    // // organisation 5 (wixy)
    // dev: "https://devtoppanapi.hashinclude.io/wixy/api/v1/",
    // prod: "https://toppanapi.hashinclude.io/wixy/api/v1/",
  },

  // transaction apis
  createTransaction: "api/v1/transactions/create-transaction/",
  // Get Transaction
  getTransaction: "api/v1/transactions/get-order-transaction-status/",
  getCurrency: "api/v1/topan-admin/get-currency",
};

export default urls;
