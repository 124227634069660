export default function themeTypography(theme) {
  return {
    fontFamily: "Manrope, sans-serif",
    h1: {
      fontSize: "24px",
      fontWeight: 600,
      color: "#000000",
      fontStyle: "normal",
    },
    h2: {
      fontSize: "22px",
      fontWeight: 500,
      color: "#000000",
      fontStyle: "normal",
    },
    h3: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#000000",
      fontStyle: "normal",
    },
    p1: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontStyle: "normal",
    },
    p2: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#000000",
      fontStyle: "normal",
    },
  };
}
