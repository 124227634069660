import { Fragment, useState, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  iPad,
  macbook,
  visa,
  visaMastercard,
  iPhone,
  watch,
} from "../../assets";
import { ProductCard } from "../../components/table/ProductCard";
import { QuantityInput } from "../../components";
import { useModal } from "../../hooks";
import { PhoneDialog } from "../../components/dialogs/PhoneDialog";
import { useAPI } from "../../hooks";
import { useToast } from "../../context";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ResponsiveProductCard from "../../components/table/ResponsiveProductCard";
import { useParams } from "react-router-dom";
import { baseURL } from "../../services/urls";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export function Dashboard() {
  const [isPhoneDialogOpen, openPhoneDialog, closePhoneDialog] = useModal();
  const { urls } = useAPI();
  const { showToast } = useToast();
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(0);
  const timeArray = [
    85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5, 0,
  ];
  const [currency, setCurrency] = useState("");
  const { orgUrl } = useParams();
  const navigate = useNavigate();

  const handleQuantityChange = (event, newValue, index) => {
    const newRows = [...rows];
    newRows[index].quantity = newValue;
    newRows[index].total = newRows[index].price * newValue;
    setRows(newRows);
    console.log("New Values", rows);
  };

  const [rows, setRows] = useState([
    {
      image: macbook,
      name: "MacBook Pro 14",
      quantity: 1,
      brand: "Apple",
      price: 1599,
      total: 1599,
    },
    {
      image: iPad,
      name: "iPad Pro 12.9",
      quantity: 1,
      brand: "Apple",
      price: 799,
      total: 799,
    },
    {
      image: iPhone,
      name: "iPhone 15 Pro",
      quantity: 1,
      brand: "Apple",
      price: 999,
      total: 999,
    },
    {
      image: watch,
      name: "Watch 9",
      quantity: 1,
      brand: "Apple",
      price: 399,
      total: 399,
    },
  ]);

  const [transactionId, setTransactionId] = useState("");
  const [cartData, setCartData] = useState({
    totalItems: 2,
    subTotal: 0,
    tax: 5,
    taxAmount: 0,
    deliveryFee: 0,
    totalAmount: 0,
    totalQuantity: 2,
  });

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ orgUrl:", orgUrl);
    let subTotal = 0;
    let totalQuantity = 0;
    rows.map((row) => {
      subTotal += row.total;
      totalQuantity += row.quantity;
    });
    let taxAmount = (cartData.tax * subTotal) / 100;
    let totalAmount = subTotal + taxAmount;

    setCartData((prev) => ({
      ...prev,
      subTotal: subTotal,
      taxAmount: taxAmount,
      totalAmount: totalAmount,
      totalQuantity: totalQuantity,
    }));
    getCurrency();
  }, [rows]);

  const handleCheckoutClick = () => {
    openPhoneDialog();
  };

  const getCurrency = () => {
    axios
      .get(
        orgUrl
          ? `${baseURL}${orgUrl}/${urls.getCurrency}`
          : `${baseURL}${urls.getCurrency}`
      )
      .then((res) => {
        let data = res.data.data;
        setCurrency(data);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          console.log("Invalid URL");
          navigate("/404/error");
        }
        console.log(err.response?.data?.message);
      });
  };

  const handlePhoneSubmit = (phone) => {
    closePhoneDialog();
    let items = rows.map((x) => ({
      product: x.name,
      price: x.price,
      quantity: x.quantity,
    }));

    const spaceIndex = phone.indexOf(" ");
    let params = {
      transaction_towards: "ecommerce",
      phone_code: phone.substring(0, spaceIndex).trim(),
      phone_number: phone.substring(spaceIndex + 1).replace(/\s/g, ""),
      user_device: "mobile",
      payment_method: "card",
      order_price: cartData.subTotal,
      total_amount: cartData.totalAmount,
      shipping_fee: cartData.deliveryFee,
      total_quantity: cartData.totalQuantity,
      orders: items,
    };

    // api
    //   .post(urls.createTransaction, params)
    axios
      .post(
        orgUrl
          ? `${baseURL}${orgUrl}/${urls.createTransaction}`
          : `${baseURL}${urls.createTransaction}`,
        params
      )
      .then((res) => {
        setShowTimer(true);
        setTransactionId(res.data.data.transaction_id);
        showToast("Transaction Initiated", "success");
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  const checkTransaction = () => {
    // api
    //   .get(urls.getTransaction + transactionId + "/")
    axios
      .get(
        orgUrl
          ? `${baseURL}${orgUrl}/${urls.getTransaction}${transactionId}/`
          : `${baseURL}${urls.getTransaction}${transactionId}/`
      )
      .then((res) => {
        let status = res.data.data.status;
        if (timer > 0 && status.toLowerCase() !== "initiated") {
          if (status.toLowerCase() === "cancelled") {
            showToast("Transaction Cancelled", "error");
          } else {
            showToast("Transaction Successful", "success");
          }
          setShowTimer(false);
          setTimer(0);
        } else if (timer <= 3) {
          if (status.toLowerCase() !== "initiated") {
            showToast("Transaction Verification Successful", "success");
          } else {
            showToast("Transaction has Timed Out", "error");
          }
        }
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  return (
    <Fragment>
      <Grid container direction={{ xs: "column", lg: "row" }} pt={10}>
        <Grid item xs={12} lg={9}>
          <Box
            sx={{
              pr: { xs: 2, md: 8 },
              pl: { xs: 2, md: 8 },
              pt: 4,
            }}
          >
            <Stack>
              <Stack
                sx={{ pt: 1 }}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="h1" sx={{ fontSize: 20 }}>
                  Shopping Cart
                </Typography>
                {/* <Typography variant="h1">2 Items</Typography> */}
              </Stack>
              <Divider sx={{ mt: 3, mb: 3 }}></Divider>
              <TableContainer sx={{ display: { xs: "none", md: "block" } }}>
                <Table
                  // sx={{ padding: 0, minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="h2"
                          sx={{ fontSize: 20 }}
                          color={"secondary.main"}
                        >
                          Product
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="h2"
                          sx={{ fontSize: 20 }}
                          color={"secondary.main"}
                        >
                          Quantity
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="h2"
                          sx={{ fontSize: 20 }}
                          color={"secondary.main"}
                        >
                          Price
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="h2"
                          sx={{ fontSize: 20 }}
                          color={"secondary.main"}
                        >
                          Total
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <ProductCard
                            image={row.image}
                            productName={row.name}
                            brandName={row.brand}
                          ></ProductCard>
                        </TableCell>
                        <TableCell align="center">
                          <QuantityInput
                            index={index}
                            value={row.quantity}
                            onChange={handleQuantityChange}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="p1">{row.price}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="p1">{row.total}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                {rows.map((row, index) => (
                  <ResponsiveProductCard data={row} key={index} />
                ))}
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box
            sx={{
              bgcolor: "backgroundGray.main",
              height: "87vh",
              pr: 5,
              pl: 5,
              pt: 4,
            }}
          >
            <Stack pb={2}>
              <Stack
                sx={{ pt: 2 }}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography variant="h1" sx={{ fontSize: 20 }}>
                  Order Summary
                </Typography>
                {showTimer && (
                  <CountdownCircleTimer
                    isPlaying={showTimer}
                    duration={90}
                    colors={["#19A089"]}
                    size={100}
                    onUpdate={(timer) => {
                      setTimer(timer);
                      if (transactionId) {
                        // if (timer === 30) {
                        //   checkTransaction();
                        // } else if (timer === 0) {
                        //   checkTransaction();
                        // }
                        if (timeArray.includes(timer)) {
                          checkTransaction();
                        }
                      }
                      if (timer === 0) {
                        setShowTimer(false);
                        setTransactionId("");
                      }
                    }}
                  >
                    {({ remainingTime }) => (
                      <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                        {remainingTime}
                      </Typography>
                    )}
                  </CountdownCircleTimer>
                )}
              </Stack>
              <Divider sx={{ mt: 4 }}></Divider>
              <Typography sx={{ mt: 4 }} variant="h2">
                Payment Method
              </Typography>
              <Box
                sx={{
                  mt: 4,
                  borderRadius: "8px",
                  // height: "200px",
                  // bgcolor: "white.main",
                }}
              >
                <img style={{ width: 150 }} src={visaMastercard} />
              </Box>
              {/* <Typography sx={{ mt: 4 }} variant="h2">
                Promo Code
              </Typography>
              <Stack direction={"row"} sx={{ height: "100px" }}>
                <Box
                  sx={{
                    mt: 4,
                    borderRadius: "8px",
                    height: "60px",
                    width: "100%",
                    bgcolor: "white.main",
                  }}
                >
                  <TextField
                    sx={{
                      color: "GrayText",
                      "& fieldset": { border: "none" },
                    }}
                    placeholder="Enter Your Code"
                    variant="outlined"
                  ></TextField>
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    mt: 4,
                    ml: -2,
                    borderRadius: "8px",
                    height: "60px",
                    width: "150px",
                    bgcolor: "red.main",
                    ":hover": {
                      bgcolor: "red.main",
                    },
                  }}
                >
                  Apply
                </Button>
              </Stack> */}
              <Divider sx={{ mt: 4 }}></Divider>
              <Stack direction={"column"}>
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  sx={{ height: "80px" }}
                >
                  <Typography sx={{ mt: 2 }} variant="h3">
                    {`Total Items ${cartData.totalQuantity}`}
                  </Typography>
                  <Typography sx={{ mt: 2 }} variant="h3">
                    {`${currency} ${cartData.subTotal}`}
                  </Typography>
                </Stack>
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  sx={{ height: "80px" }}
                >
                  <Typography sx={{ mt: 2 }} variant="h3">
                    {`Tax (${cartData.tax}%)`}
                  </Typography>
                  <Typography sx={{ mt: 2 }} variant="h3">
                    {`${currency} ${cartData.taxAmount}`}
                  </Typography>
                </Stack>
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  sx={{ height: "80px" }}
                >
                  <Typography sx={{ mt: 2 }} variant="h3">
                    Shipping fee
                  </Typography>
                  <Typography sx={{ mt: 2 }} variant="h3" color={"green.main"}>
                    Free
                  </Typography>
                </Stack>
                <Divider sx={{ mt: 0 }}></Divider>
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  sx={{ height: "80px" }}
                >
                  <Typography sx={{ mt: 4 }} variant="h3">
                    Total Amount
                  </Typography>
                  <Typography sx={{ mt: 4 }} variant="h3">
                    {`${currency} ${cartData.totalAmount}`}
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                color="green"
                sx={{
                  height: "60px",
                  width: "100%",
                  mt: 4,
                  fontSize: 18,
                  fontWeight: 700,
                }}
                onClick={handleCheckoutClick}
                disabled={showTimer || cartData.totalAmount === 0}
                disableElevation
              >
                CHECK OUT
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <PhoneDialog
        open={isPhoneDialogOpen}
        onClose={closePhoneDialog}
        onSubmit={handlePhoneSubmit}
      />
    </Fragment>
  );
}
