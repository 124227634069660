import React from "react";
import { Fragment } from "react";
import { Box, Button, IconButton, Stack, AppBar } from "@mui/material";
import {
  headerLogo,
  cartIcon,
  profileIcon,
  searchIcon,
  logo1,
} from "../../assets";

export function DashboardHeader() {
  return (
    <Fragment>
      <AppBar sx={{ backgroundColor: "#fff" }}>
        {/* <Box sx={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)" }}> */}
          <Stack
            sx={{ pr: 8, pl: {xs:1,md:8}, pt: 3, pb: 2 }}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <img
              src={logo1}
              style={{
                objectFit: "cover",
                // width: "250px",
                height: "50px",
              }}
            />
            {/* <Stack direction={"row"} gap={3}>
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <img src={searchIcon}></img>
            </IconButton>
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <img src={cartIcon}></img>
            </IconButton>
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <img src={profileIcon}></img>
            </IconButton>
          </Stack> */}
          </Stack>
        {/* </Box> */}
      </AppBar>
    </Fragment>
  );
}
