import { Fragment } from "react";
import { Box, Stack } from "@mui/material";
import { Dashboard } from "../pages/dashboard";
import { DashboardHeader } from "../components/headers/DashboardHeader";
import NotFoundPage from "../pages/NotFoundPage";
import { Route, Routes } from "react-router-dom";

export function Main() {
  return (
    <Fragment>
      <Stack>
        <Box>
          <DashboardHeader />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/:orgUrl" element={<Dashboard />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Box>
      </Stack>
    </Fragment>
  );
}
