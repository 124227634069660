import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

export const PhoneDialog = (props) => {
  const { open, onClose, onSubmit } = props;
  const [phoneInput, setPhoneInput] = useState("");

  const handlePhoneInputChange = (value) => {
    setPhoneInput(value);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle variant="h1" sx={{ textAlign: "center", mb: 2 }}>
        Enter your Phone Number
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <MuiTelInput
          sx={{ width: "100%" }}
          defaultCountry="AE"
          value={phoneInput}
          onChange={handlePhoneInputChange}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack direction={"row"} spacing={2} pb={2}>
          <Button
            variant="contained"
            color="lightGreen"
            sx={{
              textTransform: "none",
              width: 200,
              height: 50,
              fontSize: 18,
              fontWeight: 700,
            }}
            onClick={onClose}
            disableElevation
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="green"
            sx={{
              textTransform: "none",
              width: 200,
              height: 50,
              fontSize: 18,
              fontWeight: 700,
            }}
            onClick={() => onSubmit(phoneInput)}
            disableElevation
          >
            Continue
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

PhoneDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
